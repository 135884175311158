import React from "react";
import "./HealthCareInnerMainBanner.css";
import AgriImage from "../../../images/healthAndCareSegment.png";

export default function HealthCareInnerMainBanner() {
  return (
    <div>
      <div>
        <div className="HealthCareMain">
          <img className="HealthCareMain-home-image" alt="" src={AgriImage} />
          <div className="HealthCareMain-image-text-box">
            <div className="HealthCareMain-image-background-text">
              <h1 className="HealthCareMain-image-topic">
                Health & Personal Care
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./AboutHealthCare.css";

export default function AboutHealthCare() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="HealthCareInner-layout-42">
        <div className="HealthCareInner-content2">
          <div
            className="HealthCareInner-column2"
            data-aos="fade-right"
            data-aos-duration="700"
          >
            <div className="HealthCareInner-heading">
              Health & Personal Care
            </div>
          </div>
          <div className="HealthCareInner-column3">
            <div className="HealthCareInner-text">
              <p
                className="HealthCareInner-this-was-done"
                data-aos="fade-left"
                data-aos-duration="500"
              >
                The Health & Personal Care sector includes baby care, herbal
                care, pharmaceuticals, medical devices and personal care. A
                unique blend of indigenous and globally reputed brands ensures
                that this sector remains relevant to changing consumer
                preferences and across many market segments.
              </p>
              <p className="HealthCareInner-this-was-done">&nbsp;</p>
              <p
                className="HealthCareInner-this-was-done"
                data-aos="fade-left"
                data-aos-duration="700"
              >
                The Group’s Health & Personal Care sector caters to the needs of
                increasingly discerning Sri Lankan consumers supporting their
                health and well-being through a range of locally manufactured
                and imported products. Comprising a range of trusted brands,
                these products are made available island wide through a
                distribution network that is ranked amongst the top ten networks
                in the country. A unique blend of indigenous and globally
                reputed brands ensures that this sector remains relevant to
                changing consumer preferences and across many market segments.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

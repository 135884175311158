import React from "react";
import "./HealthCareGallery.css";
import HealthCareImage from "../../../images/25-Herbal-Personal-Care-300x142.jpg";

const HealthCareGallery = () => {
  return (
    <div className="healthCareGallery">
      <h1
        className="healthCareGallery-title"
        data-aos="fade-down"
        data-aos-duration="750"
      >
        Gallery
      </h1>
      <div
        className="healthCareGallery-image-container"
        data-aos="fade-up"
        data-aos-duration="750"
      >
        <img
          src={HealthCareImage}
          alt="Healthcare Gallery"
          className="healthCareGallery-image"
        />
      </div>
    </div>
  );
};

export default HealthCareGallery;

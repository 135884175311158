import React from "react";
import HealthCareInnerMainBanner from "../templates/OurSegments/HealthCare/HealthCareInnerMainBanner";
import AboutHealthCare from "../templates/OurSegments/HealthCare/AboutHealthCare";
import HealthCareGallery from "../templates/OurSegments/HealthCare/HealthCareGallery";

function HealthCare() {
  return (
    <div>
      <HealthCareInnerMainBanner />
      <AboutHealthCare />
      <HealthCareGallery />
    </div>
  );
}

export default HealthCare;
